<template>
  <div>
    <div v-for="game in games" :key="game.ID" class="py-2 tour-link">
      <div class="d-flex">
        <div class="flex-shrink-1 align-self-center text-left game-time-block">
          <div class="font-weight-normal time-start-game text-white">{{game.Time}}</div>
          <div class="small game-online" v-bind:class="[game.State === 'live' ? dataSport.textClass : 'text-white-50 state-small']">{{game.State}}</div>
        </div>
        <div class="pl-2 w-100">
          <div class="d-flex justify-content-center align-items-center">
            <div class="text-right w-100">
              <div class="font-weight-normal team-font-size small text-white" v-if="$i18n.locale === 'ru'">{{game.team1_title}}</div>
              <div class="font-weight-normal team-font-size small text-white" v-else>{{game.team1_title_eng}}</div>
              <div class="small text-white-50" v-if="$i18n.locale === 'ru'">{{game.player1_title}}</div>
              <div class="small text-white-50" v-else>{{game.player1_title_eng}}</div>
            </div>
            <div class="px-3 game-score text-center">
              <div v-if="game.ScoreTeam1 > game.ScoreTeam2">
                <div class="text-nowrap">
                  <span v-if="game.TeamOvertimeWin > 0 && game.Team1_id === game.TeamOvertimeWin" :class="dataSport.textClass">
                    OT&nbsp;
                  </span>
                  <span :class="dataSport.textClass">{{game.ScoreTeam1}}</span>
                  <span class="separator text-white">-</span>
                  <span class="text-white">{{game.ScoreTeam2}}</span>
                  <span v-if="game.TeamOvertimeWin > 0 && game.Team2_id === game.TeamOvertimeWin" :class="dataSport.textClass">
                    &nbsp;OT
                  </span>
                </div>
              </div>
              <div v-else-if="game.ScoreTeam1 < game.ScoreTeam2">
                <div class="text-nowrap">
                  <span v-if="game.TeamOvertimeWin > 0 && game.Team1_id === game.TeamOvertimeWin" :class="dataSport.textClass">
                    OT&nbsp;
                  </span>
                  <span class="text-white">{{game.ScoreTeam1}}</span>
                  <span class="separator text-white">-</span>
                  <span :class="dataSport.textClass">{{game.ScoreTeam2}}</span>
                  <span v-if="game.TeamOvertimeWin > 0 && game.Team2_id === game.TeamOvertimeWin" :class="dataSport.textClass">
                    &nbsp;OT
                  </span>
                </div>
              </div>
              <div v-else-if="game.ScoreTeam1 === game.ScoreTeam2 && game.State !== 'upcoming'">
                <div class="text-nowrap">
                  <span v-if="game.TeamBullitsWin > 0 && game.Team1_id === game.TeamBullitsWin" :class="dataSport.textClass">Б&nbsp;</span>
                  <span class="text-white">{{game.ScoreTeam1}}</span>
                  <span class="separator text-white">-</span>
                  <span class="text-white">{{game.ScoreTeam2}}</span>
                  <span v-if="game.TeamBullitsWin > 0 && game.Team2_id === game.TeamBullitsWin" :class="dataSport.textClass">&nbsp;Б</span>
                </div>

              </div>
              <div v-else>
                <b class="separator text-white">-</b>
              </div>
            </div>
            <div class="text-left w-100">
              <div class="font-weight-normal team-font-size small text-white" v-if="$i18n.locale === 'ru'">{{game.team2_title}}</div>
              <div class="font-weight-normal team-font-size small text-white" v-else>{{game.team2_title_eng}}</div>
              <div class="small text-white-50" v-if="$i18n.locale === 'ru'">{{game.player2_title}}</div>
              <div class="small text-white-50" v-else>{{game.player2_title_eng}}</div>
            </div>
          </div>
          <div class="text-nowrap text-center periods-block "><small class="text-white">{{game.Periods}}</small></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StageGames",
  props: ['games','dataSport'],
}
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
  padding: 0 2px;
}
.game-time-block {
  max-width: 60px;
  width: 100%;
}
.game-online {
  letter-spacing: 1px;
  font-size: 0.8rem;
}
.state-small{
  font-size: 0.70rem;
}
.period-block {
  line-height: 12px;
}
</style>